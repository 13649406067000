import { createAction, props } from "@ngrx/store";
import { IClientConfigDto } from "src/app/common/dto/client-config";
import { IKeycloakUserInfoDto } from "src/app/common/dto/keycloak/keycloak-user-info";
import { UserInfoDto } from "src/app/common/dto/user-info";
import { PLUGIN } from "src/app/common/enums/plugins";
import { ICFPolicy, IPolicy } from "src/app/common/interfaces/app/policy";
import { IContactForm } from "src/app/common/interfaces/dialogs/contact";
import { TimelineRangeValue } from "src/app/common/interfaces/range/range";
import { PluginEntity } from "src/app/core/interfaces/pluginEntity";
import { IDivision, IProjectEntity, MinMaxYears } from "src/app/core/interfaces/project-entity";

export const initApp = createAction("[App] Init");
export const logout = createAction("[App] Logout");
export const updatePolicy = createAction("[App] Update policy terms and conditions", props<{ policy: IPolicy }>());
export const setPolicy = createAction("[App] Set policy terms and conditions", props<{ policy: IPolicy }>());
export const loadCurrentPolicy = createAction("[App] Load current policy");
export const setCurrentPolicy = createAction("[App] Set current policy", props<{ currentPolicy: ICFPolicy }>()); // USE BELOW
export const setPluginId = createAction("[App] Set plugin config", props<{ id: PLUGIN }>());
export const setPluginEntities = createAction("[App] Set plugins", props<{ pluginEntities: Array<PluginEntity> }>());

export const fetchKeycloakUserInfo = createAction("[App] Fetch keycloak user info");
export const fetchKeycloakUserInfoSuccess = createAction(
  "[App] Fetch keycloak user profile successfully",
  props<{ payload: IKeycloakUserInfoDto }>()
);

export const fetchUserInfo = createAction("[App] Fetch user info");
export const fetchUserInfoSuccess = createAction("[App] Fetch user successfully", props<{ payload: UserInfoDto }>());
export const fetchUserInfoError = createAction("[App] Fetch user error");
export const setUserInfo = createAction("[App] Set user info", props<{ userInfo: UserInfoDto }>());

export const initEntity = createAction("[App] Init project entity", props<{ entity: IProjectEntity }>());
export const setEntity = createAction("[App] Set project entity", props<{ entity: IProjectEntity }>());

export const setEntities = createAction("[App] Set project entities", props<{ payload: Array<IProjectEntity> }>());

export const changeUserRegion = createAction("[App]: Change user region by id", props<{ entityId: string }>());
export const initUserRegion = createAction("[App]: Init user region by id", props<{ entityId: string }>());

export const fetchClientConfig = createAction("[App] Fetch client config");
export const fetchClientConfigSuccess = createAction(
  "[App] Fetch client config success",
  props<{ payload: IClientConfigDto }>()
);

export const changeYear = createAction("[App] Change year", props<{ year: number }>());
export const changeDivision = createAction("[App] Change division", props<{ division: keyof IDivision | null }>());

export const changeTimelineRange = createAction(
  "[App] Change timeline range",
  props<{ timeRange: TimelineRangeValue }>()
);

export const postContactData = createAction(
  "[App] Post contact email data",
  props<{
    form: IContactForm;
    requestType: string;
  }>()
);

export const setMinMaxYears = createAction("[App] Set project min max years", props<{ payload: MinMaxYears }>());

export const setFilterByKey = createAction(
  "[App] Set filter by key",
  props<{ key: string | number; value: any }>()
);
export const clearFilters = createAction("[App] clear filters");
